import React, { useEffect, useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Form, Row } from "react-bootstrap";

export interface IDeliveryDateForm {
  desired_delivery_date: Date|undefined;
  desired_delivery_timezone: string;
};

interface IProps {
  timezones: { [key: string]: string };
  onChange: (val: IDeliveryDateForm) => void;
  values: IDeliveryDateForm;
};

const DeliveryDateForm = (props: IProps) => {
  const [date, setDate] = useState<Date|undefined>(props.values.desired_delivery_date);
  const [timezone, setTimezone] = useState<string>(props.values.desired_delivery_timezone);

  const addDate = (date: Date, add: number): Date => {
    date.setDate(date.getDate() + add);
    return date;
  };
  const minDate = useMemo(() => addDate(new Date, 1), []);
  const maxDate = useMemo(() => addDate(new Date, 8), []);

  useEffect(() => {
    props.onChange({ desired_delivery_date: date, desired_delivery_timezone: timezone });
  }, [date, timezone]);

  const handleOnChangeTimezones = (e: React.ChangeEvent<HTMLInputElement>) => setTimezone(e.target.value);
  const onDateChange = (dateOrArr, _e) => {
    setDate(dateOrArr);
  };

  return (
    <Form.Group>
      <Form.Label> 配達希望日 </Form.Label>
      <Row>
        <Col>
          <DatePicker dateFormat="yyyy/MM/dd"
            selected={date}
            onChange={onDateChange}
            minDate={minDate}
            maxDate={maxDate}
            isClearable
            className="form-control" />
        </Col>
        <Col>
          <Form.Control as="select" onChange={handleOnChangeTimezones} custom>
            <option value="">指定なし</option>
            {Object.keys(props.timezones).map(key => (<option value={key} key={key}>{props.timezones[key]}</option>))}
          </Form.Control>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default React.memo(DeliveryDateForm);
