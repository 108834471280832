import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {numberWithDelimiter} from "../../utils";
import {useDispatch} from "react-redux";
import {retrieveProduct} from "../../service";
import styles from "./ExhibitPage.module.scss";
import { finishMounted } from "./../../stores/transition";
import Nl2br from './../../components/Nl2br';

const I18n = window.I18n;
const siteName: string = I18n.translate('views.store.name');

interface ExhibitPageProps {
  exhibitId: string;
}

const ExhibitPage = ({ exhibitId }: ExhibitPageProps) => {
  const dispatch = useDispatch();
  const [exhibit, setExhibit] = useState(undefined);
  const [selectedImage, setSelectedImage] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  if (isMounted) dispatch(finishMounted());

  useEffect(() => {
    (async () => {
      const res = await axios.get(`/${window.gon.locale}/api/exhibit_products/${exhibitId}`);
      setExhibit(retrieveProduct(res.data?.data));
      setIsMounted(true);
    })();
  }, [exhibitId]);

  useEffect(() => {
    if (!exhibit?.name) return;
    document.title = `${exhibit.name} | ${siteName}`;
  }, [exhibit]);

  return (
    <div className="container">
      <div className={styles.productBox}>
        <div className={styles.productBoxLeft}>
          <img src={exhibit?.images?.[selectedImage]} alt={exhibit?.name}/>
          <ul className={styles.thumbnails}>
            {
              exhibit?.images?.map((image, i) =>
                  <li key={`exhibit-image-${i}`}>
                    <img className={styles.thumbnail} src={image}
                         alt={exhibit?.name} onMouseOver={() => setSelectedImage(i)}/>
                  </li>)
            }
          </ul>
        </div>
        <div className={styles.productBoxRight}>
          <h1 className={styles.name}>{exhibit?.name}</h1>
          <div className={styles.description}>
            <Nl2br text={exhibit?.description} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitPage;
