import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { paginate, finishMounted } from "./../../stores/transition";

interface InquiryInterface {
    [key: string]: any;
    name: string;
    email: string;
    subject: string;
    message: string;
};

const InquiryPage = () => {
    const dispatch = useDispatch();
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [values, setValues] = useState<InquiryInterface>({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState<InquiryInterface>({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    if (isMounted) dispatch(finishMounted());
    useEffect(() => { setIsMounted(true); }, []);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
        setValues({...values, [e.target.name]: e.target.value});

    const hadleOnSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        try {
            await axios.post('/api/inquiries', values)
            dispatch(paginate('/inquiry/complete'));
        } catch (err) {
            switch (err.response.status) {
                case 422:
                    setErrors(err.response.data);
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div className="container">
            <Form onSubmit={hadleOnSubmit}>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name="name" onChange={handleOnChange} isInvalid={!!errors.name}></Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" onChange={handleOnChange} isInvalid={!!errors.email}></Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" name="subject" onChange={handleOnChange} isInvalid={!!errors.subject}></Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.subject}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" name="message" rows={6} onChange={handleOnChange} isInvalid={!!errors.message} />
                    <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <button type="submit" className="w-100">Send</button>
                </Form.Group>
            </Form>
        </div>
    );
};

export default InquiryPage;
