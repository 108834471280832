import React, { useState, useEffect, useRef, RefObject, ChangeEvent } from 'react';
import { Form } from "react-bootstrap";

interface FieldProps {
  id: string;
  label: string;
  name: string;
  type: string;
  required?: boolean;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string,
  autoComplete?: string;
  inputRef?: RefObject<HTMLInputElement>;
}

const Field = (props: FieldProps) => (
  <Form.Group>
    <Form.Label htmlFor={props.id}>
      {props.label}
    </Form.Label>
    <Form.Control
      id={props.id}
      name={props.name}
      type={props.type}
      placeholder={props.placeholder}
      required={props.required}
      autoComplete={props.autoComplete}
      value={props.value}
      onChange={props.onChange}
      ref={props.inputRef}
      />
  </Form.Group>
);

export interface ValueInterface {
  [key: string]: string;
  name: string;
  email: string;
  phone: string;
}

interface CheckoutFormProps {
  [key: string]: any;
  name?: string;
  email?: string;
  phone?: string;
  onChange?: (value: ValueInterface) => void;
}

const CheckoutForm = (props: CheckoutFormProps) => {
  const [values, setValues] = useState<ValueInterface>({
    name: props.name || '',
    email: props.email || '',
    phone: props.phone || '',
  });
  const [init, setInit] = useState<boolean>(false);
  const nameInputRef = useRef(null);

  useEffect(() => {
    if (!init && nameInputRef.current) {
      nameInputRef.current.focus();
      setInit(true);
    }
  }, [init]);

  useEffect(() => {
    if (props.onChange) props.onChange(values);
  }, [values]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void =>
    setValues({...values, [e.target.name]: e.target.value});

  return (
    <>
      <fieldset>
          <Field
              inputRef={nameInputRef}
              label="Name"
              id="name"
              name="name"
              type="text"
              placeholder="Jane Doe"
              required
              autoComplete="name"
              value={values.name}
              onChange={handleOnChange} />
          <Field
              label="Email"
              id="email"
              name="email"
              type="email"
              placeholder="janedoe@gmail.com"
              required
              autoComplete="email"
              value={values.email}
              onChange={handleOnChange} />
          <Field
              label="Phone"
              id="phone"
              name="phone"
              type="tel"
              placeholder="(941) 555-0123"
              required
              autoComplete="tel"
              value={values.phone}
              onChange={handleOnChange} />
      </fieldset>
    </>
  );
};

export default CheckoutForm;
