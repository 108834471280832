import React, {useContext, useEffect, useState, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from "react-redux";
import {Link} from './../Link';
import {authContext} from './../../contexts/AuthProvider';
import {useSelector} from "./../../stores";
import {paginate} from '../../stores/transition';

const SESSION_STORAGE_KEY = 'propmtedLogIn';

const usePrevItemCount = (count: number): null|number => {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = count;
    }, [count]);
    return ref.current;
};

const PromptLogInModa = () => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const items = useSelector(state => state.cart.items);
    const prevCount = usePrevItemCount(items.length || 0);
    const auth = useContext(authContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevCount !== 0) return;
        if (items.length === 0) return;
        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY)) return;
        setIsShow(true);
    }, [items]);

    useEffect(() => {
        if (isShow) {
            window.sessionStorage.setItem(SESSION_STORAGE_KEY, '1');
        }
    }, [isShow]);

    const handleClose = () => setIsShow(false);

    const handleClickLogin = () => {
        dispatch(paginate('/login'));
        handleClose();
    };

    if (!!auth.user) {
        return (null);
    }

    return (
        <Modal show={isShow} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    ログインしてみましょう
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-2">
                    ログインすることで各端末間でのカートの共有や、購入履歴の閲覧が可能です。
                </p>
                <p className="mb-4">
                    また配送先情報や支払い情報を事前に登録することにより、購入時の煩わしい入力を省略することができます。
                </p>
                <div className="text-center">
                    <div className="mb-2">
                        <button type="button" onClick={handleClickLogin}>ログインする</button>
                    </div>
                    <div className="mb-3">
                        <Link to="/signup" onClick={handleClose}>新規登録はこちら</Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(PromptLogInModa);
