import React, { useState, useEffect, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { initCart } from "./CartController";

interface Props extends RouteComponentProps {
    children: React.ReactElement;
}

const SyncCart = (props: Props) => {
    const [prevProps, setPrevProps] = useState(props);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.location !== prevProps.location) {
            dispatch(initCart());
        }
    }, [props.location]);

    useEffect(() => {
        dispatch(initCart());
    }, []);

    return (<>{props.children}</>);
};

export default withRouter(SyncCart);
