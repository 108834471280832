import React, { createContext, useEffect, useState } from 'react';
import axios from "./../http/axios";

interface IContext {
    isFinishInit: boolean;
    user: any;
    signin: (values: { email: string, password: string }) => Promise<void>;
    signout: () => Promise<void>;
    signup: (values: { email: string, password: string, password_confirmation: string }) => Promise<void>;
    update: (values: { user_name: string, tel: string }) => Promise<void>;
}

const useProvideAuth = (): IContext => {
    const [user, setUser] = useState<object|null>(null);
    const [isFinishInit, setIsFinishInit] = useState<boolean>(false);

    const signin = async (values): Promise<void> => {
        const res = await axios.post('/api/users/sign_in', { api_user: values });
        setUser(res.data);
    };

    const signout = async (): Promise<void> => {
        await axios.delete('/api/users/sign_out');
        setUser(null);
    };

    const signup = async (values): Promise<void> => {
        await axios.post('/api/users', { api_user: values });
    };

    const update = async (values): Promise<void> => {
      const res = await axios.put('/api/users', { api_user: values });
      setUser(res.data);
    };

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get('/api/users');
                setUser(res.data);
            } catch (err) {
                console.error(err);
            } finally {
                setIsFinishInit(true);
            }
        })();
    }, []);

    return { isFinishInit, user, signin, signout, signup, update };
};

export const authContext = createContext({} as IContext);

interface IProps {
    children: React.ReactNode;
}

const AuthProvider = (props: IProps) => {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {props.children}
        </authContext.Provider>
    );
};

export default AuthProvider;
