export function numberWithDelimiter(number, delimiter = ",") {
    try {
        const parts = number.toString().split('.');
        parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter);
        return parts[0];
    } catch(e) {
        return number
    }
}

export const csrfHeader = () => {
    const value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    return {'X-CSRF-Token': value};
};
