import React from "react";
import { Route, RouteComponentProps } from "react-router";
import Menu from "./Menu";
import AddressPage from "./AddressPage";
import UserInfoPage from "./UserInfoPage";
import CardPage from "./CardPage";
import OrdersListPage from "./OrdersListPage";

export default (props: RouteComponentProps) => (
  <div className="d-flex flex-md-row flex-column container">
    <Menu />
    <div className="w-100">
      <Route exact path={`${(props.match.path)}/address`} component={AddressPage}/>
      <Route exact path={`${(props.match.path)}/orders`} component={OrdersListPage}/>
      <Route exact path={`${(props.match.path)}/credit`} component={CardPage}/>
      <Route exact path={`${(props.match.path)}/`} component={UserInfoPage}/>
    </div>
  </div>
);
