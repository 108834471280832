import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Badge, Card, Col, ListGroup, Row, Image } from 'react-bootstrap';
import { numberWithDelimiter } from "./../../utils";
import styles from "./OrderItem.module.scss";

const I18n = window.I18n;

const badgeVariant = Object.freeze({
  await_payment: 'secondary',
  deposited: 'info',
  sent: 'success',
});

const OrderItem = (props: any) => (
  <Card className="mb-4">
    <Card.Header className="bg-white">
      <h3 className="h5 m-0 d-flex">
        {format(new Date(props.order.attributes.created_at), 'yyyy/MM/dd HH:mm')}
        <Badge variant={badgeVariant[props.order.attributes.status] || 'secondary'} className="ml-auto rounded-0">
          {I18n.translate('enums.order.status')[props.order.attributes.status]}
        </Badge>
      </h3>
    </Card.Header>
    <Card.Body>
      <dl className="row">
        <dt className="col-md-3"> お支払方法 </dt>
        <dd className="col-md-9 mb-2"> {I18n.translate('enums.order.payment_method')[props.order.attributes.payment_method]} </dd>

        <dt className="col-md-3"> お支払金額 </dt>
        <dd className="col-md-9 mb-2"> {numberWithDelimiter(props.order.attributes.price)}円 </dd>

        <dt className="col-md-3"> 伝票番号 </dt>
        <dd className="col-md-9 mb-2"> {props.order.attributes.slip_number || '(未発行)'} </dd>

        <dt className="col-md-3"> 配達希望日時 </dt>
        <dd className="col-md-9 mb-2">
          {props.order.attributes.desired_delivery_date || '(日指定なし)'}
          {props.order.attributes.desired_delivery_timezone || '(時間指定なし)'}
        </dd>

        <dt className="col-md-3"> お届け先 </dt>
        <dd className="col-md-9 mb-2">
          <span className="mr-2">
            〒{props.order.attributes.address.data.attributes.zip}
          </span>
          {props.order.attributes.address.data.attributes.address}
          {props.order.attributes.address.data.attributes.building_name}
        </dd>
      </dl>
    </Card.Body>

    <ListGroup variant="flush">
      {props.order.attributes.order_items.data.map(orderItem => (
        <ListGroup.Item key={orderItem.id}>
          <Row className="w-100">
            <Col md={3} className="text-center">
              <Image rounded src={orderItem.attributes.product.data.attributes.images[0]} className={styles.thumbnail} />
            </Col>
            <Col md={9} className="d-flex align-items-center">
              <div className="mr-2">
                {orderItem.attributes.product.data.attributes.name}
              </div>
              <div className="ml-auto text-right">
                <div>
                  {numberWithDelimiter(orderItem.attributes.unit_price)}円
                  x
                  {numberWithDelimiter(orderItem.attributes.amount)}本
                </div>
                <div className="h5">
                  {numberWithDelimiter(orderItem.attributes.unit_price * orderItem.attributes.amount)}円
                </div>
              </div>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
      <ListGroup.Item>
        <Row className="w-100">
          <Col md={{ span: 9, offset: 3 }}>
            <div className="d-flex align-items-center">
              <div className="mr-2"> 送料 </div>
              <div className="ml-auto text-right h5">
                {numberWithDelimiter(props.order.attributes.shipping_fee)}円
              </div>
            </div>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row className="w-100">
          <Col md={{ span: 9, offset: 3 }}>
            <div className="d-flex align-items-center">
              <div className="mr-2">
                消費税
              </div>
              <div className="ml-auto text-right h5">
                {numberWithDelimiter(props.order.attributes.tax_total)}円
              </div>
            </div>
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

export default React.memo(OrderItem);
