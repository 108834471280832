import React, { useState } from "react";
import { Link } from "../Link";
import AuthLink from "./../auth-link/AuthLink";
import LogoSvg from "../../images/logo.svg";
import styles from "./Header.module.scss";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnMenuClick = (e: React.MouseEvent<HTMLInputElement>): void => setIsOpen(isOpen => !isOpen);

  const closeMenu = (): void => setIsOpen(false);

  return (
    <header className="header">
      <div className={`text-center container`}>
        <Link to="/">
          <img src={LogoSvg} alt="kyoto fine wine and spirits" height="240" />
        </Link>

        <nav className={`${styles.menuWrapper} ${isOpen ? styles.menuWrapperOpen : ''}`}>
          <ul className={`flex-md-row ${styles.menu}`}>
            <li className={styles.menuLi}>
              <Link to="/" className={styles.link} onClick={closeMenu}>Top</Link>
            </li>
            <li className={styles.menuLi}>
              <Link to="/shop" className={styles.link} onClick={closeMenu}>Shop</Link>
            </li>
            <li className={styles.menuLi}>
              <Link to="/company" className={styles.link} onClick={closeMenu}>Company</Link>
            </li>
            <li className={styles.menuLi}>
              <Link to="/privacy-policy" className={styles.link} onClick={closeMenu}>Privacy Policy</Link>
            </li>
            <li className={styles.menuLi}>
              <Link to="/inquiry" className={styles.link} onClick={closeMenu}>Contact</Link>
            </li>
            <li className={styles.menuLi}>
              <a href="/magazine/register" className={styles.link} target="_blank" rel="noopener noreferrer">Mail Magazine</a>
            </li>
            <li className={styles.menuLi}>
              <AuthLink className={styles.link} onClick={closeMenu} />
            </li>
          </ul>
        </nav>
        <div className={styles.navBtn} onClick={handleOnMenuClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
};
