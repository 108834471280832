import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { history } from "../stores";

export const PAGE_TRANSION_DURATION = 400;

// State
type TransitionState = {
    isPaginating: boolean;
};
const initialState: TransitionState = {
    isPaginating: false,
};

// Slice
const slice = createSlice({
    name: 'transition',
    initialState,
    reducers: {
        paginate: (state, action: PayloadAction<string>) => {
            setTimeout(() => history.push(action.payload), PAGE_TRANSION_DURATION);
            return Object.assign({}, state, {isPaginating: true});
        },
        finishMounted: (state, action: PayloadAction<void>) => {
            return Object.assign({}, state, {isPaginating: false});
        }
    }
});

// Reducerをエクスポート
export default slice.reducer;

// Action Creatorsをエクスポート
export const { paginate, finishMounted } = slice.actions;
