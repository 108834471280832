import React, { useEffect } from "react";
import ExhibitItem from "./ExhibitItem";
import styles from "./ExhibitList.module.scss";
import {Link} from "../Link";
import {compact} from "lodash";

interface Props {
  items: Array<any>;
  top: boolean;
}

const ExhibitList = (props: Props) => {
  return (
    <ul className={compact([styles.grid, props.top ? styles.top : undefined]).join(' ')}>
      {
        props.items?.map((exhibit, i) =>
          <li key={`exhibit-${i}`} className={styles.item}>
            <Link to={`/exhibits/${exhibit.id}`}>
              <ExhibitItem exhibit={exhibit}/>
            </Link>
          </li>)
      }
    </ul>
  );
};

export default ExhibitList;
