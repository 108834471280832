import React, { useState, useEffect, ReactNode } from 'react';
import { Table } from "react-bootstrap";
import axios from "axios";
import CardInfo from "./../card/CardInfo";
import styles from "./Bank.module.scss";

interface BankInterface {
  financial: string;
  branch: string;
  deposit_type: string;
  account_number: string;
  account_name: string;
};

interface IProps {
  topChildren: ReactNode;
  bottomChildren: ReactNode;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  card: any;
};

const RegisteredCard = (props: IProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [banks, setBanks] = useState<Array<BankInterface>>([]);

  useEffect(() => {
    (async () => {
      const res = await axios.get('/api/banks')
      setBanks(res.data);
    })();
  }, []);

  const handleOnSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      if (isProcessing) return;
      setIsProcessing(true);
      await props.onSubmit(e);
    } catch {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleOnSubmit} className={styles.form}>
      {props.topChildren}

      <CardInfo card={props.card} />

      {props.bottomChildren}
      <button type="submit" className={styles.submitButton} disabled={isProcessing} >
          {isProcessing ? 'Processing...' : '購入'}
      </button>
    </form>
  );
};

export default RegisteredCard;
