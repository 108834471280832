import React, {useCallback, useContext, useState, useRef} from "react";
import { useDispatch } from "react-redux";
import axios, { type CancelTokenSource } from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { finishMounted } from "./../../stores/transition";
import {ProductList} from "../../components/products/ProductList";
import { ProductSearch, type IParams } from "../../components/products/ProductSearch";
import {retrieveProduct} from "./../../service";
import { createSearchParams } from './../../functions/url';

const ProductListPage = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const cancelToken = useRef<CancelTokenSource>(axios.CancelToken.source());

  const fetch = useCallback(async (params: IParams): Promise<void> => {
    try {
      if (cancelToken.current) {
        cancelToken.current.cancel('require new request.');
      }
      cancelToken.current = axios.CancelToken.source();

      const url = new URL(`/${window.gon.locale}/api/products`, location.origin);
      url.search = createSearchParams<IParams>(params);
      const res = await axios.get(url.toString(), { cancelToken: cancelToken.current.token });
      setProducts(res.data?.data?.map(retrieveProduct));
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn('cancel request.', error.message);
      } else {
        throw error;
      }
    }
  }, []);

  const handleChange = useCallback(async (params: IParams): Promise<void> => {
    await fetch(params);
    setIsMounted(true);
  }, [fetch]);

  if (isMounted) dispatch(finishMounted());

  return (
    <Container fluid>
      <Row className="flex-md-row-reverse">
        <Col>
          <ProductList items={products} />
        </Col>
        <Col md={3}>
          <ProductSearch onChange={handleChange} />
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(ProductListPage);
