import React, { ReactNode } from "react";
import {v4 as uuid} from "uuid";

interface Props {
    text: string|undefined;
}

const Nl2br = (props: Props) => {
    const prefix = uuid();
    const nl2br = (str: string = ''): ReactNode => {
        const regex = /(\n)/g
        return str.split(regex).map((line, i): ReactNode => line.match(regex) ? <br key={`${prefix}-${i}`}/> : line);
    };

    return (<>{nl2br(props.text)}</>);
};

export default Nl2br;
