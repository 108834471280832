import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import LinkButton from "./../../components/LinkButton";
import { finishMounted } from "./../../stores/transition";
import styles from "./CompanyPage.module.scss";

const I18n = window.I18n;
const siteName: string = I18n.translate('views.store.name');

const CompanyPage = () => {
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);

  if (isMounted) dispatch(finishMounted());
  useEffect(() => { setIsMounted(true); }, []);

  return (
    <div className="container">
      <h3 className="h3 text-center">会社概要</h3>

      <Table className={styles.table}>
        <caption className={styles.caption}>
          ストア情報
        </caption>
        <tbody>
          <tr>
            <th>会社名(商号)</th>
            <td>{siteName}株式会社</td>
          </tr>
          <tr>
            <th>ストア名</th>
            <td>{siteName}</td>
          </tr>
          <tr>
            <th>ストア名(カタカナ)</th>
            <td>キョウトファインワインアンドスピリッツ</td>
          </tr>
          <tr>
            <th>ストア紹介</th>
            <td>他では少しお目にかかれない(かもしれない)ウィスキーをお売りするストアです。</td>
          </tr>
        </tbody>
      </Table>

      <Table className={styles.table}>
        <caption className={styles.caption}>
          酒類販売者管理者情報
        </caption>
        <tbody>
          <tr>
            <th>販売場の名称及び所在地</th>
            <td>Kyoto Fine Wine and Spirits事務所<br/>京都市左京区岡崎円勝寺町58</td>
          </tr>
          <tr>
            <th>酒類販売管理者の氏名</th>
            <td>王子武貞</td>
          </tr>
          <tr>
            <th>酒類販売管理研修受講年月日</th>
            <td>令和1年9月13日</td>
          </tr>
          <tr>
            <th>次回研修の受講期限</th>
            <td>令和4年9月12日</td>
          </tr>
          <tr>
            <th>研修実施団体名</th>
            <td>京都府小売酒販組合連合会</td>
          </tr>
        </tbody>
      </Table>

      <Table className={styles.table}>
        <caption className={styles.caption}>
          酒類販売者管理者情報
        </caption>
        <tbody>
          <tr>
            <th>販売場の名称及び所在地</th>
            <td>Kyoto Fine Wine and Spirits事務所<br/>京都市左京区岡崎円勝寺町58</td>
          </tr>
          <tr>
            <th>酒類販売管理者の氏名</th>
            <td>王子武貞</td>
          </tr>
          <tr>
            <th>酒類販売管理研修受講年月日</th>
            <td>令和1年9月13日</td>
          </tr>
          <tr>
            <th>次回研修の受講期限</th>
            <td>令和4年9月12日</td>
          </tr>
          <tr>
            <th>研修実施団体名</th>
            <td>京都府小売酒販組合連合会</td>
          </tr>
        </tbody>
      </Table>

      <Table className={styles.table}>
        <caption className={styles.caption}>
          お問合せ情報
        </caption>
        <tbody>
          <tr>
            <th>お問い合わせ窓口</th>
            <td>王子武貞</td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>〒606-8344</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>京都府京都市左京区岡崎円勝寺町58番地</td>
          </tr>
          <tr>
            <th>お問い合わせ電話番号</th>
            <td>090-1155-3104</td>
          </tr>
          <tr>
            <th>お問い合わせメールアドレス</th>
            <td><a href="mailto:kyotofinewineandspirits@gmail.com">kyotofinewineandspirits@gmail.com</a></td>
          </tr>
          <tr>
            <th>ストア営業日/時間 </th>
            <td>土・日・祝日以外 10-17時</td>
          </tr>
        </tbody>
      </Table>

      <Table className={styles.table}>
        <caption className={styles.caption}>
          免許・許可証
        </caption>
        <tbody>
          <tr>
            <th rowSpan={3}>種類の免許</th>
            <th>免許名称</th>
            <td>酒類販売業</td>
          </tr>
          <tr>
            <th>番号</th>
            <td>左京法 第247号</td>
          </tr>
          <tr>
            <th>酒類免許発行税務署</th>
            <td>左京税務署</td>
          </tr>
        </tbody>
      </Table>

      <LinkButton to="/" className="w-100">戻る</LinkButton>
    </div>
  );
};

export default CompanyPage;
