import React, { memo } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { CategoryList } from './CategoryList';
import { useParamsContext } from './contexts/ParamsProvider';
import type { Series } from './types';

const series = window.gon.series?.data ?? [];

export const ProductSearchForm: React.VFC = memo((): React.ReactElement => {
  const { params, handleChange, handleChangeUnSelectSeries } = useParamsContext();

  return (
    <Card className="mb-3">
      <Card.Body>
        <Form.Group>
          <Form.Label>
            在庫
          </Form.Label>
          <Form.Control as="select" name="unsold" value={params.unsold} onChange={handleChange}>
            <option value="">全て</option>
            <option value="1">在庫あり</option>
            <option value="0">在庫なし</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            種類
          </Form.Label>
          <Form.Control as="select" name="liquor_type" value={params.liquor_type} onChange={handleChange}>
            <option value="">全て</option>
            {Object.keys(window.gon.liquor_type).map((key: string) =>
              <option key={key} value={key}>
                {window.gon.liquor_type[key]}
              </option>
            )}
          </Form.Control>
        </Form.Group>

        <Form.Label>
          シリーズ
        </Form.Label>
        <Card>
          <Card.Body>
            <ul>
              {series.map((series: Series) =>
                <CategoryList key={series.id} series={series} />
              )}
              <label className="d-block mb-3">
                <input type="checkbox" className="mr-2" checked={params.unselect_series} onChange={handleChangeUnSelectSeries} />
                未分類
              </label>
            </ul>
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  );
});
