import React from "react";
import styles from "./ProductItem.module.scss";
import {numberWithDelimiter} from "../../utils";

export const ProductItem = ({ product }) => <>
    <div className={styles.product}>
        <div className={styles.imageWrapper}>
            <img className={styles.image} src={ product.images[0] } alt={ product.name }/>
        </div>
        <div className={styles.price}>価格 { numberWithDelimiter(product.price_include_tax) }円(税込)</div>
        <div className={styles.name}>[{window.gon.liquor_type[product.liquor_type]}] { product.name }</div>
    </div>
</>;
