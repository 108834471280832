import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import styles from "../root/RootPage.module.scss";
import { finishMounted } from "../../stores/transition";
import ExhibitList from "../../components/exhibits/ExhibitList";
import {retrieveProduct} from "../../service";

export const ExhibitsListPage = () => {
    const dispatch = useDispatch();
    const [exhibits, setExhibits] = useState([]);
    const [isMounted, setIsMounted] = useState(false);

    if (isMounted) dispatch(finishMounted());

    //
    useEffect(() => {
        (async () => {
            const res = await axios.get(`/${window.gon.locale}/api/exhibit_products`);
            console.log('api', res);
            setExhibits(res.data?.data?.map(retrieveProduct));
            setIsMounted(true);
        })();
    }, []);

    return <div className="container">
        <h2 className={styles.header}>Our Products</h2>
        <ExhibitList items={exhibits} top={false} />
    </div>;
};
