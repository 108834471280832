import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LinkButton from "./../../components/LinkButton";
import { finishMounted } from "./../../stores/transition";
import styles from "./PrivacyPolicyPage.module.scss";

const I18n = window.I18n;
const siteName: string = I18n.translate('views.store.name');

const PrivacyPolicyPage = () => {
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);

  if (isMounted) dispatch(finishMounted());
  useEffect(() => { setIsMounted(true); }, []);

  return (
      <div className="container">
        <div className={styles.wrapper}>

          <h3 className="h3 text-center">プライバシーポリシー</h3>

          <p className="text-right py-2">
            {siteName}
            <br/>
            情報管理責任者: 永田勇貴
          </p>

          <h4 className="h4">プライバシーポリシー詳細</h4>
          <p className={styles.description}>
            「{siteName}」（以下、「当社」といいます）ではお客様に当社をご利用していただく際に、お客様の氏名、住所、電話番号、メールアドレスなどお取引やご連絡に必要な情報を開示していただきます。当社は、個人情報保護法を順守し、このプライバシーポリシーにのっとって個人情報を取り扱います。
          </p>

          <ol className={styles.listWrapper}>
            <li>
              <h5 className={styles.listTitle}>個人情報の利用目的</h5>
              <p className={styles.description}>当社は、当社が取得した個人情報を以下の目的で利用します。</p>
              <ol className={styles.childList}>
                <li className={styles.childListItem}>お客様への商品の発送および代金の請求のため</li>
                <li className={styles.childListItem}>当社のサービス改善を行うため</li>
                <li className={styles.childListItem}>お客様からのご要望やお問い合わせに対する回答をするため</li>
              </ol>
            </li>

            <li>
              <h5 className={styles.listTitle}>個人情報の管理について</h5>
              <p className={styles.description}>当社は以下の体制で個人情報を管理します。</p>
              <ul className={styles.childList}>
                <li className={styles.childListItem}>個人情報保護法やガイドラインに従って必要な社内体制を整備し、従業員から個人情報の取り扱いを適正に行う旨の誓約書を取得します。</li>
                <li className={styles.childListItem}>個人情報の利用を業務上必要な社員だけに制限し、個人情報が含まれる媒体などの保管・管理などに関する規則を作り、個人情報保護のための措置を講じます。</li>
                <li className={styles.childListItem}>システムに保存されている個人情報については、業務上必要な社員だけが利用できるようアカウントとパスワードを用意し、アクセス権限管理を実施します。なお、アカウントとパスワードは漏えい、滅失のないよう厳重に管理します。</li>
                <li className={styles.childListItem}>インターネットによる個人情報にかかわるデータ伝送時のセキュリティーのため、必要なウェブページに業界標準の暗号化通信であるSSLを使用します。</li>
                <li className={styles.childListItem}>サービスに支障が生じないことを前提として、個人情報の受領時から一定期間経過後、個人情報は随時削除していきます。</li>
              </ul>
            </li>

            <li>
              <h5 className={styles.listTitle}>第三者提供について</h5>
              <p className={styles.description}>
                当社は、お客様の同意がない限り、個人情報を第三者に開示することはありません。ただし、以下の事例に該当する場合はその限りではありません。
              </p>
              <ul className={styles.childList}>
                <li className={styles.childListItem}>法令に基づき裁判所や警察等の公的機関から要請があった場合</li>
                <li className={styles.childListItem}>法令に特別の規定がある場合</li>
                <li className={styles.childListItem}>お客様や第三者の生命・身体・財産を損なうおそれがあり、本人の同意を得ることができない場合</li>
                <li className={styles.childListItem}>法令や当社の利用規約・注意事項に反する行動から、当社の権利、財産またはサービスを保護または防御する必要があり、本人の同意を得ることができない場合</li>
              </ul>
            </li>

            <li>
              <h5 className={styles.listTitle}>個人情報の開示について</h5>
              <p className={styles.description}>
                お客様から個人情報の開示要求があった場合は、本人であることが確認できた場合に限り開示します。
              </p>
              <small className={styles.small}>
                注: 本人確認の方法<br/>
                本人であることが証明できるもの（免許証、保険証など）の写しを、当社宛てに郵送してください。<br/>
                内容を確認させていただき、本人であることが明確になり次第、開示させていただきます。
              </small>
            </li>
          </ol>

          <LinkButton to="/" className="w-100 mt-4">戻る</LinkButton>
        </div>
      </div>
  );
};

export default PrivacyPolicyPage;
