import React, { useContext } from "react";
import { Link } from "./../Link";
import { history } from "./../../stores";
import { authContext } from "./../../contexts/AuthProvider";

interface IProps {
    onClick: () => void;
    className: string;
};

const AuthLink = (props: IProps) => {
    const auth = useContext(authContext);
    if (auth.user) {
        return <Link to="/mypage" className={props.className} onClick={props.onClick}>My Page</Link>;
    }
    return <Link to="/login" className={props.className} onClick={props.onClick}>Log In</Link>
};

export default AuthLink;
