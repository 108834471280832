import { createContext as orgCreateContext, useContext, type Provider } from 'react';

export const createContext = <ContextType> (): readonly [() => ContextType, Provider<ContextType | undefined>] => {
  const ctx = orgCreateContext<ContextType | undefined>(undefined);

  const useCtx = (): ContextType => {
    const context = useContext(ctx);
    if (context === undefined) {
      throw new Error("useCtx must be inside a Provider with a value");
    }
    return context;
  }

  return [useCtx, ctx.Provider] as const;
};
