import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {Product} from "../../types";
import {concat, compact, clone, findIndex} from "lodash";
import axios from "./../../http";

const cartStorageKey = 'cart';

const updateLoaclCart = (items: Array<CartItem>): void =>
  localStorage.setItem(cartStorageKey, JSON.stringify(items));

const updateBackendCart = async (items: Array<CartItem>): Promise<void> => {
  try {
    const cart_product_ids = {};
    items.forEach(item => cart_product_ids[item.id] = item.amount);
    await axios.put('/api/carts', { cart: { cart_product_ids } });
  } catch (err) {
    console.error(err);
  }
};

const fetchLocalCart = (): Array<CartItem> => {
  const json = localStorage.getItem(cartStorageKey);
  return JSON.parse(json) || [];
};

// 非同期Action
export const initCart = createAsyncThunk(
  'cart/initCart',
  async (_: undefined, { getState }) => {
    try {
      const res = await axios.get('/api/carts');
      const items = (res.data || []) as Array<CartItem>;
      updateLoaclCart(items);
      return items;
    } catch (err) {
      console.error(err);
      return fetchLocalCart();
    }
});

export class CartItem implements Product {
    id: string;
    name: string;
    description: string;
    price: number;
    price_include_tax: number;
    images: Array<string>;
    amount: number = 1;
    buyable_limit: number = 0;
}
type CartState = {
    open: boolean;
    items: Array<CartItem>;
};
const initialState: CartState = {
    open: false,
    items: [],
};
const slice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<CartItem>) => {
            const localItems = fetchLocalCart();
            const existing = findIndex(localItems, ['id', action.payload.id]);
            let items:Array<CartItem>;
            if (existing > -1) {
                const item = clone(localItems[existing]);
                if (!item.buyable_limit || item.amount < item.buyable_limit) item.amount++;
                items = concat(localItems.slice(0, existing), item, localItems.slice(existing + 1));
            } else {
                const item = clone(action.payload);
                item.amount = 1;
                items = [...localItems, item];
            }
            // 待つ必要はない
            updateLoaclCart(items);
            updateBackendCart(items);
            return ({...state, open: true, items});
        },
        updateCartItem: (state, action: PayloadAction<[CartItem, number]>) => {
            const localItems = fetchLocalCart();
            const existing = findIndex(localItems, ['id', action.payload[0].id]);
            let item = clone(localItems[existing]);
            if (action.payload[1] === 0) {
                item = null; // delete
            } else if (!item.buyable_limit || action.payload[1] <= item.buyable_limit) {
                item.amount = action.payload[1];
            }
            const items = compact(concat(localItems.slice(0, existing), item, localItems.slice(existing + 1)));
            // 待つ必要はない
            updateLoaclCart(items);
            updateBackendCart(items);
            return ({...state, open: true, items});
        },
        emptyCard: (state, action: PayloadAction<void>) => {
          // 待つ必要はない
          updateLoaclCart([]);
          updateBackendCart([]);
          return ({...state, items: []})
        },
        openCart: state => ({...state, open: true}),
        hideCart: state => ({...state, open: false}),
    },
    extraReducers: (builder): void => {
        builder.addCase(initCart.fulfilled, (state, action: PayloadAction<Array<CartItem>>) => ({...state, items: action.payload }));
    }
});
export const totalPrice:(items: Array<CartItem>) => number
    = items => items?.reduce((acc, item) => acc + item.amount * item.price, 0) || 0;
export const cartReducer = slice.reducer;
export const {addToCart, updateCartItem, emptyCard, openCart, hideCart} = slice.actions;
