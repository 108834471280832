import React, {useState, ReactNode} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {Form} from "react-bootstrap";
import styles from "./Payment.module.scss";
import CardFieldForm from './CardFieldForm';

const SubmitButton = ({processing, error, children, disabled}) => (
    <button
        className={`${styles.SubmitButton} ${error ? styles.SubmitButton__error : ''}`}
        type="submit"
        disabled={processing || disabled}
    >
        {processing ? 'Processing...' : children}
    </button>
);

type PaymentProps = {
    onPaymentMethodReady: (PaymentMethod) => Promise<void>;
    topChildren: ReactNode;
    bottomChildren: ReactNode;
    name: string;
    email: string;
    phone: string;
};

const CheckoutForm = (props: PaymentProps) => {
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);

    const createPaymentMethod = async () => {
        try {
            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: { name: props.name, email: props.email, phone: props.phone },
            });

            // stripeは失敗した時点でerrorをthrowするので多分この処理いらないけど。。
            if (payload.error) {
                throw new Error(payload.error.message);
            }

            return payload;
        } catch (err) {
            setError(err);
            throw err;
        }
    };

    const handleSubmit = async (event): Promise<void> => {
        event.preventDefault();
        if (processing) return;
        setProcessing(true);

        try {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            if (!stripe || !elements) {
                return;
            }

            if (error) {
                elements.getElement('card').focus();
                return;
            }

            const payload = await createPaymentMethod();
            await props.onPaymentMethodReady(payload.paymentMethod);
        } finally {
            setProcessing(false);
        }
    };

    return <form className={styles.Form} onSubmit={handleSubmit}>
        {props.topChildren}
        <CardFieldForm onError={setError} error={error} />
        {props.bottomChildren}
        <SubmitButton processing={processing} error={error} disabled={!stripe}>
            購入
        </SubmitButton>
    </form>
};

let stripePromise;
const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(window.gon?.stripe_key);
    }
    return stripePromise;
};

export const Payment = (paymentProps: PaymentProps) =>
    <div>
        <Elements stripe={getStripe()}>
            <CheckoutForm {...paymentProps} />
        </Elements>
    </div>;
