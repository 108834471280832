import React, { memo } from 'react';
import { ProductSearchForm } from './ProductSearchForm';
import { ParamsProvider } from './contexts/ParamsProvider';

import type { ReactElement } from 'react';
import type { IParams } from './types';

export { IParams };

interface IProps {
  onChange: (params: IParams) => void | Promise<void>;
}

export const ProductSearch: VFC<IProps> = memo(({ onChange }): ReactElement => {
  return (
    <ParamsProvider onChange={onChange}>
      <ProductSearchForm />
    </ParamsProvider>
  );
});
