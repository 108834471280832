import React from "react";
import { useSelector } from "../../stores";
import {Header} from "../header/Header";
import Footer from "../footer/Footer";
import {Cart} from "../cart/Cart";
import ProgressBar from "../progress-bar/ProgressBar";
import AgeConfirmModal from "./../ageconfirm/AgeConfirmModal";
import PromptLogInModal from "./../prompt-login/PromptLogInModal";
import CartBtn from "./../cart/CartBtn";
import styles from "./Layout.module.scss";
import {MailMagazineBanner} from "../mail-magazine-banner/MailMagazineBanner";

export const Layout = ({children}) => {
    const isPaginating = useSelector(state => state.transition.isPaginating);
    return (<>
        <ProgressBar />
        <Header />
        <main className={`${styles.animate} ${isPaginating ? styles.active : ''}`}>
            <div>
                {children}
            </div>
            <Cart />
            <Footer />
        </main>
        <MailMagazineBanner/>
        <CartBtn />
        <AgeConfirmModal />
        <PromptLogInModal />
    </>);
};
