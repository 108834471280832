import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { Col, Form, Row } from 'react-bootstrap';
import CardFieldForm from './../../components/stripe/CardFieldForm';
import CardInfo from './../../components/card/CardInfo';
import { finishMounted } from "./../../stores/transition";
import { authContext } from './../../contexts/AuthProvider';
import axios from './../../http';

const CardTokenForm = () => {
  const auth = useContext(authContext);
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [card, setCard] = useState<any>({});
  const [error, setError] = useState(null);
  const [indent, setIndent] = useState(null);

  if (isMounted) dispatch(finishMounted());

  useEffect(() => {
    (async () => {
      const res = await axios.get('/api/cards');
      setCard(res.data?.card);
      setIsMounted(true);
    })();
  }, []);

  const fetchIndent = async () => {
    if (indent) return indent;
    const res = await axios.get('/api/cards/edit');
    setIndent(res.data);
    return res.data;
  }

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (isProcessing) return;
    try {
      setIsProcessing(true);
      if (!stripe)
      if (!elements) return;

      if (error) {
        elements.getElement('card').focus();
        return;
      }

      const indent = await fetchIndent();
      const result = await stripe.confirmCardSetup(
        indent.client_secret,
        {
          payment_method: {
            card: elements.getElement('card'),
            billing_details: { email: auth.user.email }
          }
        }
      );

      if (result.error) {
        setError(result.error);
      } else {
        const res = await axios.put('/api/cards', result);
        setCard(res.data?.card);
        setIndent(null);
      }

    } catch (err) {
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleOnDelete = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (isProcessing) return;
    try {
      setIsProcessing(true);
      axios.delete('/api/cards');
      setCard(null);
    } catch (err) {
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  if (card) {
    return (
      <form onSubmit={handleOnDelete}>
        <CardInfo card={card} />
          
        <button type="submit" className="w-100" disabled={isProcessing} >
            {isProcessing ? 'Processing...' : '削除'}
        </button>
      </form>
    );
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <CardFieldForm onError={(e) => setError(e.error)}></CardFieldForm>
      <button type="submit" className="w-100" disabled={isProcessing} >
          {isProcessing ? 'Processing...' : '更新'}
      </button>
    </form>
  );
};

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(window.gon?.stripe_key);
  }
  return stripePromise;
};

const CardPage = () => (
  <Elements stripe={getStripe()}>
    <CardTokenForm />
  </Elements>
);

export default CardPage;
