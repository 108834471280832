import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "./../../stores";
import { CartIcon } from "./../icons";
import { openCart, hideCart, initCart } from "./CartController";
import styles from "./CartBtn.module.scss";

const CartBtn = () => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.cart.open);

    const handleOnClick = (e: React.MouseEvent<HTMLInputElement>): void => {
      dispatch(initCart());
      open ? dispatch(hideCart()) : dispatch(openCart());
    };

    return (
        <div className={styles.wrapper}>
            <button type="button" onClick={handleOnClick} className={styles.btn}>
                <CartIcon color="#FFF" className={styles.svg} />
            </button>
        </div>
    );
};

export default CartBtn;
