import React, { useEffect, useState } from 'react';
import { Container, Form, Row, Col } from "react-bootstrap";
import moment from 'moment';
import LogoSvg from "../../images/logo.svg";
import styles from './AgeConfirmModal.module.scss';

const LOCAL_STORAGE_KEY = 'ageConfirmed';

const isAgeConfirmed = () => !!window.localStorage.getItem(LOCAL_STORAGE_KEY);

const range = (from: number): (to: number) => Array<number> =>
  (to: number): Array<number> =>
    ([...Array(to - from + 1)].map((_: undefined, i: number): number => from + i));

const YEAR_LIST_LIMIT = 100;
const YEAR_LIMIT = 20;

interface IValues {
  year?: number;
  month?: number;
  day?: number;
};

const AgeConfirmModal = () => {
  const date = moment();
  const [defaultPosition, setDefaultPosition] = useState<string>('');
  const [ageConfirm, setAgeConfirm] = useState<boolean>(isAgeConfirmed());
  const [values, setValues] = useState<IValues>({ year: date.year() - YEAR_LIMIT, month: 1, day: 1 });
  const [isUnder20, setIsUnder20] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (ageConfirm) return;
    setDefaultPosition(document.body.style.position);
    document.body.style.position = 'fixed';
  }, []);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setValues({...values, [e.currentTarget.name]: e.currentTarget.value});

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement>): void => {
    setError('');
    const diffYear = moment().diff(moment([values.year, values.month - 1, values.day]), 'year');
    if (isNaN(diffYear)) return setError('存在しない日付が入力されました。');
    if (diffYear < YEAR_LIMIT) return setIsUnder20(true);
    document.body.style.position = defaultPosition;
    window.localStorage.setItem(LOCAL_STORAGE_KEY, '1');
    setAgeConfirm(isAgeConfirmed());
  };

  const range1: (to: number) => Array<number> = range(1);

  if (ageConfirm) return (null);

  return (
    <div className={styles.wrapper}>
      <Container fluid="sm">
        <img className={styles.image} src={LogoSvg} alt="kyoto fine wine and spirits" height="48" />
        <p className={styles.description}>
          20歳未満の飲酒は法律で禁止されております。<br/>
          適正飲酒の観点から20歳以上の方を対象としております。
        </p>

        {!isUnder20 &&
          <>
            <p className={styles.description}>あなたの生年月日を入力してください。</p>
            <Row className="justify-content-center">
              <Form.Group as={Col} controlId="formGridYear" md={3}>
                <Form.Control as="select" name="year" value={values.year} onChange={handleOnChange} required>
                  {range(date.year() - YEAR_LIST_LIMIT)(date.year()).map((i: number) => <option key={i} value={i}>{i}年</option>)}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridMonth" md={2}>
                <Form.Control as="select" name="month" value={values.month} onChange={handleOnChange} required>
                  {range1(12).map((i: number) => <option key={i} value={i}>{i}月</option>)}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridMonth" md={2}>
                <Form.Control as="select" name="day" value={values.day} onChange={handleOnChange} required>
                  {range1(31).map((i: number) => <option key={i} value={i}>{i}日</option>)}
                </Form.Control>
              </Form.Group>
            </Row>
            {error &&
              <Row className="justify-content-center">
                <Form.Group as={Col} controlId="formGridMonth" md={7}>
                  <small className="text-danger">{error}</small>
                </Form.Group>
              </Row>
            }
            <button type="button" onClick={handleOnClick}>Enter</button>
          </>
        }
        {isUnder20 &&
          <p className={styles.description}>
            申し訳ありません、あなたは法定年齢以下です。<br/>
            私達は私達のサイトであなたを歓迎することはできません。
          </p>
        }
      </Container>
    </div>
  );
};

export default AgeConfirmModal;
