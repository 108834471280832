import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface IProps {
  card: { last4: string, exp_month: string, exp_year: string };
}

const CardInfo = (props: IProps) => (
  <>
    <Form.Label> Card No </Form.Label>
    <Form.Group as={Row}>
      <Col md={9}>
        <Form.Control value={`**** **** **** **** ${props.card.last4}`} readOnly />
      </Col>
      <Col md={3}>
        <Form.Control value={`${props.card.exp_month}/${props.card.exp_year}`} readOnly />
      </Col>
    </Form.Group>
  </>
);

export default CardInfo;
