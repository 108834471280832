import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LinkButton from "./../../components/LinkButton";
import { finishMounted } from "./../../stores/transition";
import style from "./CompletePage.module.scss";

const CompletePage = () => {
    const dispatch = useDispatch();
    const [isMounted, setIsMounted] = useState(false);

    if (isMounted) dispatch(finishMounted());
    useEffect(() => { setIsMounted(true); }, []);

    return (
        <div className="text-center container">
        <h3 className={`${style.title} text-success`}>注文が確定されました。</h3>
        <p className={style.description}>
        ありがとうございます。<br/>
        商品の購入が完了しました。<br/>
        登録されたメールアドレス宛に詳細をお送りしていますので、ご確認ください。
        </p>
        <small className={style.small}>メールの到着には数分かかる場合がございます。しばらくたってもメールが到着しない場合はお問い合わせください。</small>
        <LinkButton to="/" className="w-100">戻る</LinkButton>
        </div>
    );
};

export default CompletePage;
