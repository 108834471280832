import React, { useState, useEffect, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";

interface Props extends RouteComponentProps {
    children: ReactNode;
}

const ScrollToTop = (props: Props) => {
    const [prevProps, setPrevProps] = useState(props);

    useEffect(() => {
        if (props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }, [props.location]);

    return (<>{props.children}</>);
};

export default withRouter(ScrollToTop);
