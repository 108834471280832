import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "./../../stores";
import { finishMounted } from "./../../stores/transition";
import { paginate } from './../../stores/transition';
import { Link } from './../../components/Link';
import SnackBar from './../../components/snackbar/Snackbar';
import axios from "./../../http";

const I18n = window.I18n;

interface IValues {
  email: string;
};

const PasswordPage = () => {
  const dispatch = useDispatch();
  const isPaginating: boolean = useSelector(state => state.transition.isPaginating);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [values, setValues] = useState<IValues>({ email: '' });
  const [isShowSnackBar, setIsShowSnackBar] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);

  useEffect(() => { if (isPaginating) dispatch(finishMounted()); }, [isPaginating]);

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      e.preventDefault();
      if (isProcessing) return;
      setIsProcessing(true);
      await axios.post('/api/users/password', { api_user: values })
      dispatch(paginate('/password/thanks'));
    } catch(err) {
      console.error(err);
      if (err.response?.status !== 422) return;
      setErrors(err.response?.data?.errors || []);
      setIsShowSnackBar(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <form onSubmit={handleOnSubmit}>
      <Row>
        <Col md={8} className="mx-auto">
          <Card>
            <Card.Body>
              <Form.Group>
                {I18n.translate('devise.passwords.new.forgot_your_password')}
              </Form.Group>
              <hr />
              <Form.Group>
                <Form.Label>{I18n.translate('activerecord.attributes.user.email')}</Form.Label>
                <Form.Control type="email" name="email" onChange={handleOnChange} />
              </Form.Group>
              <Form.Group>
                <button type="submit" className="w-100" disabled={isProcessing}>
                  {isProcessing ? 'Processing...' : I18n.translate('devise.passwords.new.send_me_reset_password_instructions')}
                </button>
              </Form.Group>
              <Form.Group>
                <Link to="/login">
                  {I18n.translate('devise.sessions.new.sign_in')}
                </Link>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <SnackBar onClose={() => setIsShowSnackBar(false)} show={isShowSnackBar} variant="danger">
        <>{errors.map((error, i) => <div key={i}>{error}</div>)}</>
      </SnackBar>
    </form>
  );
};

export default PasswordPage;
