import {I18n} from "../../i18n";
const siteName: string = I18n.translate('views.store.name');

export const titleMiddleware = routes => next => {
    const getTitle = (payload): string => {
        if (!payload?.location?.pathname) return;
        switch (payload.location.pathname) {
            case '/company':
                return `Company | ${siteName}`;
            case '/inquiry':
                return `Contact | ${siteName}`;
            case '/privacy-policy':
                return `Privacy Policy | ${siteName}`;
            default:
                return siteName;
        }
    };
    return action => {
        const title = getTitle(action.payload);
        if (title) document.title = title;
        return next(action);
    };
}
