import {CartItem} from "../../components/cart/CartController";
import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {concat, compact, clone, findIndex} from "lodash";
import axios from './../../http';

interface ITimezone {
  [key: string]: string;
}

// 非同期Action
export const fetchShippingFee = createAsyncThunk(
  'checkout/fetchShippingFee',
  async (ids: Array<string>, { getState }) => {
    const params = { id: ids };
    const res = await axios.get(`/${window.gon.locale}/api/shipping`, { params });
    return res.data;
  }
);
export const fetchTaxRate = createAsyncThunk(
  'checkout/fetchTaxRate',
  async (val, { getState }) => {
    const res = await axios.get(`/${window.gon.locale}/api/taxrate`);
    return res.data;
  }
);
export const fetchDeliveryTimeZone = createAsyncThunk(
  'checkout/fetchDeliveryTimeZone',
  async (val, { getState }) => {
    const res = await axios.get(`/${window.gon.locale}/api/timezones`);
    return res.data;
  }
);

type CheckoutState = {
    items: Array<CartItem>;
    shippingFee: number;
    paymentMethodFee: number;
    taxRate: number;
    timezones: ITimezone;
}
const initialState: CheckoutState = {
    items: [],
    shippingFee: 0,
    paymentMethodFee: 0,
    taxRate: 0,
    timezones: {},
};
const slice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        checkout: (state, action: PayloadAction<Array<CartItem>>) => {
            const items = action.payload;
            return ({...state, items});
        },
        updateCheckoutItem: (state, action: PayloadAction<[CartItem, number]>) => {
            const existing = findIndex(state.items, ['id', action.payload[0].id]);
            let item = clone(state.items[existing]);
            item.amount = action.payload[1];
            const items = concat(state.items.slice(0, existing), item, state.items.slice(existing + 1));
            return ({...state, items});
        },
        setPaymentMethodFee: (state, action: PayloadAction<number>) => ({ ...state, paymentMethodFee: action.payload }),
    },
    extraReducers: (builder): void => {
        builder.addCase(fetchShippingFee.fulfilled, (state, action: PayloadAction<{fee: number}>) => ({...state, shippingFee: action.payload.fee-0 }));
        builder.addCase(fetchTaxRate.fulfilled, (state, action: PayloadAction<{rate: number}>) => ({...state, taxRate: action.payload.rate-0 }));
        builder.addCase(fetchDeliveryTimeZone.fulfilled, (state, action: PayloadAction<ITimezone>) => ({...state, timezones: action.payload }));
    }
});
export const checkoutReducer = slice.reducer;
export const { checkout, updateCheckoutItem, setPaymentMethodFee } = slice.actions;
