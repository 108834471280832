import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { authContext } from './../../contexts/AuthProvider';
import { useSelector } from "./../../stores";
import { finishMounted } from "./../../stores/transition";
import { paginate } from './../../stores/transition';
import { Link } from './../../components/Link';
import { initCart } from "./../../components/cart/CartController";
import SnackBar from './../../components/snackbar/Snackbar';

const I18n = window.I18n;

interface IValues {
  email: string;
  password: string;
};

const LoginPage = () => {
  const auth = useContext(authContext);
  const dispatch = useDispatch();
  const isPaginating: boolean = useSelector(state => state.transition.isPaginating);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [values, setValues] = useState<IValues>({ email: '', password: '' });
  const [isShowSnackBar, setIsShowSnackBar] = useState<boolean>(false);

  useEffect(() => { if (isPaginating) dispatch(finishMounted()); }, [isPaginating]);

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      e.preventDefault();
      if (isProcessing) return;
      setIsProcessing(true);
      await auth.signin(values);
      dispatch(initCart());
      dispatch(paginate('/mypage'));
    } catch(err) {
      console.error(err);
      setIsShowSnackBar(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setValues({ ...values, [e.target.name]: e.target.value });

  return (
    <div className="container">
      <form onSubmit={handleOnSubmit}>
        <Row>
          <Col md={8} className="mx-auto">
            <Card>
              <Card.Body>
                <Form.Group>
                  {I18n.translate('devise.sessions.new.sign_in')}
                </Form.Group>
                <hr />
                <Form.Group>
                  <Form.Label>{I18n.translate('activerecord.attributes.user.email')}</Form.Label>
                  <Form.Control type="email" name="email" onChange={handleOnChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{I18n.translate('activerecord.attributes.user.password')}</Form.Label>
                  <Form.Control type="password" name="password" onChange={handleOnChange} />
                </Form.Group>
                <Form.Group>
                  <button type="submit" className="w-100" disabled={isProcessing}>
                    {isProcessing ? 'Processing...' : I18n.translate('devise.sessions.new.sign_in')}
                  </button>
                </Form.Group>
                <Form.Group>
                  <Link to="/signup" className="d-block mb-2">
                    {I18n.translate('devise.registrations.new.sign_up')}
                  </Link>
                  <Link to="/password" className="d-block mb-2">
                    {I18n.translate('devise.passwords.new.forgot_your_password')}
                  </Link>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <SnackBar onClose={() => setIsShowSnackBar(false)} show={isShowSnackBar} variant="danger">
          <>ログインできませんでした。</>
        </SnackBar>
      </form>
    </div>
  );
};

export default LoginPage;
