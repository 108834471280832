import React, { memo, useMemo } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { useParamsContext } from './contexts/ParamsProvider';
import type { Series } from './types';

interface IProps {
  series: Series;
  nest?: number;
}

export const CategoryList: React.VFC<IProps> = memo(({ series, nest }) => {
  const { params, handleChangeSeries } = useParamsContext();

  if (nest === undefined) {
    nest = 0;
  }

  const checked = useMemo((): boolean => params.series.includes(series.attributes.hashid), [params, series]);

  return (
    <>
      <label className="d-block mb-3" style={{ marginLeft: `${nest}rem` }}>
        <input type="checkbox" value={series.attributes.hashid} className="mr-2" checked={checked} onChange={handleChangeSeries} />
        {series.attributes.name}
      </label>
      {series.attributes.children.map((child: { data: Series }) => (
        <CategoryList key={child.data.id} series={child.data} nest={nest + 1} />
      ))}
    </>
  );
});
