import React, { ReactNode } from "react";
import { paginate } from './../stores/transition';
import { useDispatch } from "react-redux";

interface LinkProps {
    to: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void,
    children: ReactNode;
}

const LinkButton = (props: LinkProps) => {
    const dispatch = useDispatch();

    const handleOnClick = e => {
        if (props.onClick) props.onClick(e);
        dispatch(paginate(props.to));
    };

    return (
        <button onClick={handleOnClick} {...props}>
            {props.children}
        </button>
    );
};

export default LinkButton;
