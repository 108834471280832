import React from "react";
import {useSelector} from "../../stores";
import {useDispatch} from "react-redux";
import styles from "./Cart.module.scss";
import {updateCartItem, hideCart} from "./CartController";
import {checkout} from "../../pages/checkout/CheckoutController";
import { PlusIcon, TimesIcon, MinusIcon } from './../icons';
import {numberWithDelimiter} from "../../utils";
import {Link} from "../Link";
import {clone} from "lodash";
import AuthLink from "../auth-link/AuthLink";

export const Cart = () => {
    const open = useSelector(state => state.cart.open);
    const items = useSelector(state => state.cart.items);
    const dispatch = useDispatch();
    const totalPrice = items?.reduce((acc, item) => acc + item.amount * item.price_include_tax, 0) || 0;
    const openCheckout = () => {
        dispatch(checkout(clone(items)));
        dispatch(hideCart());
    };
    return <div className={open ? styles.cart__open : styles.cart }>
        <header className="mb-2">
            <h3 className={styles.header}>カート</h3>
            <span className={styles.close} onClick={() => dispatch(hideCart())}>
                <TimesIcon className={styles.times} />
            </span>
        </header>
        <ul>
            {
                !items && 'カートは空です'
            }
            {
                items?.map((item, i) =>
                    <li className={styles.cartItem} key={`cart-product-${i}`}>
                        <div className="mt-2">{ item.name }</div>
                        <div className={styles.controller}>
                            <img className={`${styles.image} mt-2`} src={ item?.images[0] } alt={ item.name } />
                            <button type="button" className={styles.spinBtn} onClick={e => dispatch(updateCartItem([item, item.amount - 1]))}>
                                <MinusIcon color="#FFF" className={styles.spinBtnSvg} />
                            </button>
                            <input className={`${styles.miniInput}`} type="number" value={item.amount}
                                   onChange={e => dispatch(updateCartItem([item, parseInt(e.target.value)]))}
                            />
                            <button type="button"
                                onClick={e => dispatch(updateCartItem([item, item.amount + 1]))}
                                className={`${styles.spinBtn} ${item.buyable_limit && (item.buyable_limit <= item.amount) ? 'text-muted' : ''}`}>
                                <PlusIcon color="#FFF" className={styles.spinBtnSvg} />
                            </button>
                            <button type="button"
                                onClick={e => dispatch(updateCartItem([item, 0]))}
                                className={`${styles.spinBtn} bg-danger ml-2`}>
                                <TimesIcon color="#FFF" className={styles.spinBtnSvg} />
                            </button>
                        </div>
                        <div className={styles.priceBox}>
                            <span>{numberWithDelimiter(item.price_include_tax)}</span>
                            <span className="m-2">x</span>
                            <span>{item.amount}</span>
                            <span className="m-2">=</span>
                            <span>{numberWithDelimiter(item.price_include_tax * item.amount)}</span>
                        </div>
                    </li>
                )
            }
        </ul>
        <footer className={styles.footer}>
            <div className={styles.totalPrice}>{numberWithDelimiter(totalPrice)}</div>
            <Link to="/checkout" onClick={openCheckout}><button>購入</button></Link>
        </footer>
        <div className={styles.cartAuthLink}>
            <AuthLink onClick={() => dispatch(hideCart())} className={null} />
        </div>
    </div>;
};
