import React from "react";
import styles from "./Loading.module.scss";

export default () => (
  <div className={styles.container}>
    <div className={styles.ldsRipple}>
      <div />
      <div />
    </div>
  </div>
);
