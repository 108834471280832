import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { finishMounted } from "./../../stores/transition";
import { Form } from "react-bootstrap";
import { AddressComponent, newEmptyAddress } from "./../../components/address/AddressComponent";
import axios from './../../http';
import SnackBar from './../../components/snackbar/Snackbar';

const AddressPage = () => {
  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [address, setAddress] = useState(newEmptyAddress());
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  if (isMounted) dispatch(finishMounted());

  useEffect(() => {
    (async () => {
      const res = await axios.get('/api/address');
      setAddress(old => {
        if (res.data?.country) old.country.code = res.data.country;
        if (res.data?.zip) old.zip = res.data.zip;
        if (res.data?.address) old.address = res.data.address;
        if (res.data?.building_name) old.building_name = res.data.building_name;
        return {...old};
      });
      setIsMounted(true);
    })();
  }, []);

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      e.preventDefault();
      if (isProcessing) return;
      setIsProcessing(true);

      const values = {
        country: address.country.code,
        zip: address.zip,
        address: address.address,
        building_name: address.building_name
      }
      await axios.put('/api/address', values);
      setIsSuccess(true);
    } catch(err) {
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <AddressComponent onAddressChange={setAddress} address={address} />

      <button type="submit" className="w-100" disabled={isProcessing} >
          {isProcessing ? 'Processing...' : '更新'}
      </button>

      <SnackBar onClose={() => setIsSuccess(false)} show={isSuccess}>
        <>保存しました。</>
      </SnackBar>
    </Form>
  );
};

export default AddressPage;
