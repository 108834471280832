import React from 'react';
import { Link } from './../../components/Link';
import LogOutButton from './../../components/logout-button/LogOutButton';
import styles from './Menu.module.scss';

const Menu = () => (
  <ul className={styles.list}>
    <li className={styles.item}><Link className={styles.link} to="/mypage">User Info</Link></li>
    <li className={styles.item}><Link className={styles.link} to="/mypage/address">Address</Link></li>
    <li className={styles.item}><Link className={styles.link} to="/mypage/orders">Order History</Link></li>
    <li className={styles.item}><Link className={styles.link} to="/mypage/credit">Credit</Link></li>
    <li className={styles.item}><LogOutButton /></li>
  </ul>
);

export default Menu;
