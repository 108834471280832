import React from "react";
import { Route, RouteComponentProps } from "react-router";
import ThanksPage from "./ThanksPage";
import PasswordPage from "./PasswordPage";

export default (props: RouteComponentProps) => (
  <div className="container">
    <Route exact path={`${(props.match.path)}/thanks`} component={ThanksPage}/>
    <Route exact path={`${(props.match.path)}/`} component={PasswordPage}/>
  </div>
);
