import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../stores";
import { PAGE_TRANSION_DURATION } from '../../stores/transition'
import styles from "./ProgressBar.module.scss";

const ProgressBar = () => {
    const isPaginating = useSelector(state => state.transition.isPaginating);
    const [isLoading, setIsLoading] = useState(false);
    const [width, setWidth] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isPaginating) {
            setIsLoading(true);
        }
        if (isLoading) {
            setTimeout(() => setWidth(50), 0);
        }
        if (!isPaginating && isLoading) {
            setTimeout(() => setWidth(100), 0);
            setWidth(100);
            setTimeout(() => {
                setIsLoading(false);
                setWidth(0);
            }, PAGE_TRANSION_DURATION);
        }
    }, [isPaginating, isLoading]);

    // NOTE: isLoadingで返すDOM自体を変えてしまうと仮想DOMが置き換えられるだけで
    // 実DOMは同じDOM扱いとなってアニメーションがバグる
    return (<>
        <div className={`${styles.progress} ${isLoading ? '' : 'd-none'}`} style={{width: `${width}%`}}/>
        <div className={`bar ${isLoading ? 'd-none' : ''}`}/>
    </>);
};

export default ProgressBar;
