import React, { useEffect } from "react";
import {ProductItem} from "./ProductItem";
import styles from "./ProductList.module.scss";
import {Link} from "../Link";
import _ from "lodash";

interface Props {
    items: Array<any>
}

export const ProductList = (props: Props) => {
    return <ul className={styles.grid}>
        {
            props.items?.map((product, i) =>
                <li key={`product-${i}`}
                    className={[styles.item, product.sold_out ? styles.soldOut : ''].join(' ')}>
                    <Link to={`/products/${product.id}`}><ProductItem product={product}/></Link></li>)
        }
    </ul>;
};
