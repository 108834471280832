import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LinkButton from "./../../components/LinkButton";
import { finishMounted } from "./../../stores/transition";
import style from "./InquiryCompletePage.module.scss";

const InquiryCompletePage = () => {
    const dispatch = useDispatch();
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => { setIsMounted(true); }, []);
    useEffect(() => { dispatch(finishMounted()); }, [isMounted]);

    return (
        <div className="text-center container">
            <h3 className={`${style.title} text-success`}>お問い合わせが完了しました。</h3>
            <p className={style.description}>
                お問い合わせいただきありがとうございました。<br/>
                お問い合わせを受け付けました。
            </p>
            <LinkButton to="/" className="w-100">戻る</LinkButton>
        </div>
    );
};

export default InquiryCompletePage;
