import React, {useContext, useEffect, useState, memo} from "react";
import axios from "axios";
import {numberWithDelimiter} from "../../utils";
import {addToCart} from "../../components/cart/CartController";
import {Link} from "../../components/Link";
import {useDispatch} from "react-redux";
import {retrieveProduct} from "../../service";
import styles from "./ProductPage.module.scss";
import { finishMounted } from "./../../stores/transition";
import Nl2br from './../../components/Nl2br';

const I18n = window.I18n;
const siteName: string = I18n.translate('views.store.name');

interface ProductPageProps {
    productId: string;
}

interface Series {
  attributes: {
    hashid: string;
    name: string;
    parent: {
      data: Series | null;
    }
  }
}

const SeriesList = memo(({ series, index = 0 }: { series: Series | null | undefined, index?: number }) => {
  if (series === null || series === undefined) {
    return (<></>);
  }

  return (
    <>
      {index !== 0 && ' / '}
      <SeriesList series={series.attributes.parent.data} index={index + 1} />
      {!!series.attributes.parent.data && ' > '}
      <Link to={`/shop?series[]=${series.attributes.hashid}`} className={styles.link}>
        {series.attributes.name}
      </Link>
    </>
  );
});

export const ProductPage = memo(({productId}: ProductPageProps) => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState(undefined);
    const [selectedImage, setSelectedImage] = useState(0);
    const [isMounted, setIsMounted] = useState(false);

    if (isMounted) dispatch(finishMounted());

    useEffect(() => {
        (async () => {
            const res = await axios.get(`/${window.gon.locale}/api/products/${productId}`);
            setProduct(retrieveProduct(res.data?.data));
            setIsMounted(true);
        })();
    }, [productId]);

    useEffect(() => {
        if (!product?.name) return;
        document.title = `${product.name} | ${siteName}`;
    }, [product]);

    return <div className="container">
        <div className={styles.productBox}>
            <div className={styles.productBoxTop}>
                <img src={product?.images?.[selectedImage]} alt={product?.name}/>
                <ul className={styles.thumbnails}>
                    {
                        product?.images?.map((image, i) =>
                            <li key={`product-image-${i}`}>
                                <img className={styles.thumbnail} src={image}
                                     alt={product?.name} onMouseOver={() => setSelectedImage(i)}/></li>)
                    }
                </ul>
            </div>
            <div className={styles.productBoxBottom}>
                <div className={styles.series}>
                  {(product?.leaf_series ?? []).map((series: { data: Series }, idx: number) => <SeriesList series={series.data} key={series.data.id} />)}
                </div>
                <h1 className={styles.name}>[{window.gon.liquor_type[product?.liquor_type]}] {product?.name}</h1>
                <div className={styles.price}>価格 {numberWithDelimiter(product?.price_include_tax)}円(税込)</div>
                {
                    product?.sold_out && <div className="text-center"><span className={styles.soldOut}>SOLD OUT</span></div>
                }
                {
                    !product?.sold_out && <button className={styles.buy} onClick={e => dispatch(addToCart(product))}>カートに入れる</button>
                }
                <div className={styles.description}>
                    <Nl2br text={product?.description} />
                </div>
            </div>
        </div>
        </div>;
});
