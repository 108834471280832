import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {AjaxComplete} from "../ajax-complete/AjaxComplete";
import {YubinBango} from "./yubinbango-core";
import axios from "axios";
import {I18n} from "../../i18n";

export interface Address {
    country: any;
    zip: string;
    address: string;
    building_name: string;
}
export const newEmptyAddress: () => Address =
    () => ({country: {code: 'jp', name: '日本'}, zip: '', address: '', building_name: ''});
declare interface AddressComponentProps {
    address?: Address;
    onAddressChange?: (Address) => void;
    required?: boolean;
}

export const AddressComponent = (props: AddressComponentProps) => {
    const [address, setAddress] = useState(props.address || newEmptyAddress());
    const onAddressChangeSelf = (address, zipUpdated = false) => {
        if (props.onAddressChange) {
            props.onAddressChange(address);
        }
        setAddress(address);
        if (zipUpdated) {
            (async () => {
                const zip = address.zip.replace(/-/g, '');
                if (zip.length !== 7) return;

                const addressInfo = await YubinBango.Core.getAddress(zip);
                const {region, locality, street, extended} = addressInfo;
                const str = `${region}${locality}${street}${extended}`;
                setAddress({...address, address: str});
            })();
        }
    };
    const updateCountry = country => onAddressChangeSelf({...address, country});
    const updateAddress = str => onAddressChangeSelf({...address, address: str});
    const updateBuildingName = building_name => onAddressChangeSelf({...address, building_name});
    const updateZip = zip => onAddressChangeSelf({...address, zip}, true);

    return <div>
        <div className="form-label">{I18n.translate('views.settings.shipping_address')}</div>
        <Form.Group>
            <Row>
                <Col>
                    <AjaxComplete url="/api/countries" delay={300}
                                  defaultValue={address.country}
                                  component={Form.Control}
                                  candidate={({candidate}) => <span>{candidate.name}</span>}
                                  onSelected={updateCountry}
                                  placeholder={I18n.translate('views.settings.country')}
                                  required={props.required}
                    />
                </Col>
                <Col>
                    <Form.Control type="text" placeholder={I18n.translate('views.settings.zip')} value={address.zip}
                                  onChange={e => updateZip(e.target.value)}
                                  required={props.required}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Form.Control type="text" placeholder={I18n.translate('views.settings.address')}
                                  value={address.address} onChange={(e) => updateAddress(e.target.value)}
                                  required={props.required}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Form.Control type="text" placeholder={I18n.translate('views.settings.building_name')}
                                  value={address.building_name} onChange={(e) => updateBuildingName(e.target.value)}
                    />
                </Col>
            </Row>
        </Form.Group>
    </div>;
};
