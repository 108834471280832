import React, { useContext, useState } from 'react';
import { useDispatch } from "react-redux";
import { authContext } from "./../../contexts/AuthProvider";
import { paginate } from './../../stores/transition';

const LogOutButton = () => {
  const dispatch = useDispatch();
  const auth = useContext(authContext);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleOnClick = async (e: React.MouseEvent<HTMLInputElement>): Promise<void> => {
    try {
      if (isProcessing) return;
      setIsProcessing(true);
      await auth.signout();
    } catch(err) {
      // TODO: スナックバーとかにする
      alert('ログインできませんでした。');
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (<button type="button" onClick={handleOnClick}>Log Out</button>);
};

export default LogOutButton;
