import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import LinkButton from './../../components/LinkButton';

const ThanksPage = () => (
  <div className="text-center container">
    <Alert>
      <Alert.Heading className="text-success">メール認証が完了しました。</Alert.Heading>
      <p>
        メール認証が完了しました。<br/>
        ログイン後サービスをご利用いただくことでより便利にご利用いただけます。
      </p>
    </Alert>
    <LinkButton to="/login" className="w-100">ログイン</LinkButton>
  </div>
);

export default ThanksPage;
