import React, { useContext, useMemo } from 'react';
import { Redirect, Route } from 'react-router';
import { authContext } from './../contexts/AuthProvider';
import Loading from "./../components/loading/Loading";

interface IProps {
  component: React.FunctionComponent<any>;
  [x: string]: any;
}

const AuthRoute = ({ component, ...rest }: IProps) => {
  const auth = useContext(authContext);

  const handleRender = (props: any): React.ReactElement<any> =>
    auth.user ? React.createElement(component, props) : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);

  if (!auth.isFinishInit) return (<Loading />);
  return (<Route {...rest} render={handleRender} />);
};

export default AuthRoute;
