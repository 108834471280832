import { combineReducers } from "redux";
import { createBrowserHistory } from 'history'
import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { titleMiddleware } from "./../routes/middleware";

// reducers
import {checkoutReducer as checkout} from "../pages/checkout/CheckoutController";
import {cartReducer as cart} from "../components/cart/CartController";
import transitionReducer from "./transition";

export const history = createBrowserHistory();

const reducer = combineReducers({
    router: connectRouter(history),
    transition: transitionReducer,
    cart, checkout,
});

const middlewares = [...getDefaultMiddleware(), titleMiddleware, routerMiddleware(history), logger].map(middleware => applyMiddleware(middleware));
const store = createStore(reducer, {}, compose(...middlewares) as any);

export default store;

export type RootState = ReturnType<typeof reducer>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
