import React, { useState, useEffect, ReactNode } from 'react';
import { Table } from "react-bootstrap";
import axios from "axios";
import styles from "./Bank.module.scss";

interface BankInterface {
  financial: string;
  branch: string;
  deposit_type: string;
  account_number: string;
  account_name: string;
};

interface BankProps {
  topChildren: ReactNode;
  bottomChildren: ReactNode;
  onSubmit: (e: React.FormEvent) => Promise<void>;
};

const Bank = (props: BankProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [banks, setBanks] = useState<Array<BankInterface>>([]);

  useEffect(() => {
    (async () => {
      const res = await axios.get('/api/banks')
      setBanks(res.data);
    })();
  }, []);

  const handleOnSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      if (isProcessing) return;
      setIsProcessing(true);
      await props.onSubmit(e);
    } catch {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleOnSubmit} className={styles.form}>
      {props.topChildren}

      <h5 className="mb-2">銀行振込振込先</h5>

      {banks.map(bank => (
        <Table>
          <tbody>
            <tr>
              <th className="text-nowrap">金融機関</th>
              <td>{bank.financial} {bank.branch}</td>
            </tr>
            <tr>
              <th className="text-nowrap">口座番号</th>
              <td>{bank.deposit_type} {bank.account_number}</td>
            </tr>
            <tr>
              <th className="text-nowrap">口座名義</th>
              <td>{bank.account_name}</td>
            </tr>
          </tbody>
        </Table>
      ))}

      {props.bottomChildren}
      <button type="submit" className={styles.submitButton} disabled={isProcessing} >
          {isProcessing ? 'Processing...' : '購入'}
      </button>
    </form>
  );
};

export default Bank;
