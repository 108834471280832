import React, {useState, useEffect, useContext} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { finishMounted } from "../../stores/transition";
import ExhibitList from "../../components/exhibits/ExhibitList";
import {retrieveProduct} from "../../service";
import {ProductList} from "../../components/products/ProductList";
import styles from "./RootPage.module.scss";
import {Link} from "../../components/Link";

export const RootPage = () => {
  const dispatch = useDispatch();
  const [exhibits, setExhibits] = useState([]);
  const [products, setProducts] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  if (isMounted) dispatch(finishMounted());

  useEffect(() => {
    (async () => {
      const res = await axios.get(`/${window.gon.locale}/api/root`);
      setExhibits(res.data?.exhibits?.data?.map(retrieveProduct));
      setProducts(res.data?.products?.data?.map(retrieveProduct));
      setIsMounted(true);
    })();
  }, []);

  return <>
    <div className={styles.shop}>
      <div className="container">
        <h2 className={styles.header}>Shop</h2>
        <ProductList items={products} />
        <div className={styles.linkContainer}>
          <Link to="/shop" className={styles.allProducts}>View All</Link>
        </div>
      </div>
    </div>
    <div className={styles.exhibits}>
      <div className="container">
        <h2 className={styles.header}>Our Products</h2>
        <ExhibitList items={exhibits} top={true} />
        <div className={styles.linkContainer}>
          <Link to="/products" className={[styles.allProducts, styles.light].join(' ')}>View More</Link>
        </div>
      </div>
    </div>
  </>;
};
