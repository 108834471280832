export const fetchQueryParams = <Params>(defaultParams: Params): Params => {
  const params = new URLSearchParams(location.search);
  const keys = [...new Set(params.keys())];

  const searchParams: Params = {};
  keys.forEach((key: string): void => {
    const values = params.getAll(key);
    if (values.lengtha <= 0) {
      return;
    }

    key = key.replace(/\[\]$/, '');
    if (Array.isArray(defaultParams[key])) {
      searchParams[key] = values;
    } else {
      searchParams[key] = values[0];
    }
  });
  return searchParams;
};

export const createSearchParams = <Params>(params: Params): URL => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key: keyof Params): void => {
    const value = params[key];
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      const arrayKey = `${key}[]`;
      searchParams.delete(arrayKey);
      value.forEach((item: string) => { searchParams.append(arrayKey, item); });
    } else {
      searchParams.set(key, value);
    }
  });
  return searchParams;
};
