import React from 'react';
import { Form} from "react-bootstrap";

interface MemoProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string|undefined;
};

const MemoForm = (props: MemoProps) => (
  <Form.Group>
    <Form.Label>注文に際してご要望や、配送の日時指定等ある場合はこちらにご記入ください。</Form.Label>
    <Form.Control as="textarea" rows={3} value={props.value} onChange={props.onChange} />
  </Form.Group>
);

export default MemoForm;
