import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductSearch } from './../index';
import { createContext } from './../../../../functions/context';
import { createSearchParams, fetchQueryParams } from './../../../../functions/url';

import type { ComponentProps, PropsWithChildren } from 'react';
import type { IParams } from './../types';

const initParams = (): IParams => {
  const defaultParams: IParams = {
    unsold: '',
    liquor_type: '',
    series: [],
    unselect_series: false,
  };

  return { ...defaultParams, ...fetchQueryParams<Iparams>(defaultParams) };
};

const updateQueryParam = (params: IParams) => {
  const url = new URL(window.location);
  url.search = createSearchParams<IParams>(params);
  window.history.replaceState({}, '', url);
};

interface ParamsContextInterface {
  params: IParams;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeSeries: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeUnSelectSeries: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const [useContext, ContextProvider] = createContext<ParamsContextInterface>();

export const ParamsProvider: React.FC<PropsWithChildren<ComponentProps<typeof ProductSearch>>> = ({ children, onChange }) => {
  const [params, setParams] = useState<IParams>(initParams());

  const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.persist();
    setParams((prev: IParams): IParams => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const handleChangeSeries = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setParams((prev: IParams): IParams => {
      const series = new Set(prev.series);
      if (e.target.checked) {
        series.add(e.target.value);
      } else {
        series.delete(e.target.value);
      }
      return { ...prev, series: [...series] };
    });
  }, []);

  const handleChangeUnSelectSeries = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setParams((prev: IParams): IParams => ({ ...prev, unselect_series: e.target.checked }));
  }, []);

  useEffect((): void => {
    updateQueryParam(params);
    onChange(params);
  }, [updateQueryParam, params]);

  const value = useMemo(() => (
    {
      params,
      handleChange,
      handleChangeSeries,
      handleChangeUnSelectSeries,
    }
  ), [
    params,
    handleChange,
    handleChangeSeries,
    handleChangeUnSelectSeries,
  ]);

  return <ContextProvider value={value}>{children}</ContextProvider>;
};

export const useParamsContext = useContext;
