import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Alert, Col, Row } from 'react-bootstrap';
import LinkButton from './../../components/LinkButton';
import { useSelector } from "./../../stores";
import { finishMounted } from "./../../stores/transition";
import styles from './ThanksPage.module.scss';

const ThanksPage = () => {
  const dispatch = useDispatch();
  const isPaginating: boolean = useSelector(state => state.transition.isPaginating);

  useEffect(() => { if (isPaginating) dispatch(finishMounted()); }, [isPaginating]);

  return (
    <div className="text-center">
      <Alert>
        <Alert.Heading className="text-success">登録が完了しました。 </Alert.Heading>
        <p>
          登録したメールアドレスに「会員登録手続き用のURL」を送信しました。<br/>
          メール本文のリンクをクリックして会員登録を進めてください。
        </p>
      </Alert>
      <div className="h5"> メールが届かない場合 </div>
      <Row>
        <Col md={8} className="mx-auto">
          <ul className="mb-4">
            <li className={styles.listItem}> スパムメールフォルダや迷惑メールフォルダに届いていないか確認してください</li>
            <li className={styles.listItem}> メールアドレスを今一度確認し、メールを再送してください</li>
            <li className={styles.listItem}> 上記で解決できない場合はお問い合わせよりご連絡ください</li>
          </ul>
        </Col>
      </Row>
      <LinkButton to="/" className="w-100">戻る</LinkButton>
    </div>
  );
};

export default ThanksPage;
