import React, {useEffect, useState} from "react";
import {isEmpty, throttle} from "lodash";
import axios from "axios";
import styles from "./AjaxComplete.module.scss";
import {v4 as uuid} from "uuid";

interface Props {
    component: (any) => any;
    candidate: (any) => any;
    defaultValue?: {name: string, code: string};
    url: string;
    delay?: number;
    placeholder: string;
    onSelected: (any) => any, 
    required: boolean;
    key?: any;
}

export const AjaxComplete = ({component, candidate, defaultValue, url, delay, placeholder, onSelected, required, key = uuid()}: Props) => {
    const Component = component;
    const Candidate = candidate;
    const [internalValue, setInternalValue] = useState(defaultValue ? defaultValue.name : '');
    const [candidates, setCandidates] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showCandidates, setShowCandidates] = useState(false);
    const [selecting, setSelecting] = useState(!!defaultValue);
    const fetchFromApi = throttle((q) => {
        if (isEmpty(internalValue)) {
            setCandidates([]);
            setShowCandidates(false);
            return;
        }
        axios.get(`${url}?q=${q}`).then(res => {
            if (q !== internalValue) {
                return;
            }
            const {data} = res;
            setCandidates(data);
            setShowCandidates(true);
        });
    }, delay);
    const onSelectedSelf = candidate => {
        setSelecting(true);
        setInternalValue(candidate.name)
        setSelectedIndex(-1);
        setCandidates([]);
        setShowCandidates(false);
        onSelected(candidate);
        setTimeout(() => {
            setSelecting(false);
        }, 33);
    };
    useEffect(() => {
        if (!selecting) {
            fetchFromApi(internalValue);
        }
    }, [internalValue]);
    const onKeyDown = e => {
        if (!showCandidates) {
            return;
        }
        if (e.key === 'ArrowDown') {
            setSelectedIndex(Math.min(candidates?.length || 0, selectedIndex + 1));
        }
        if (e.key === 'ArrowUp') {
            setSelectedIndex(Math.max(-1, selectedIndex - 1));
        }
        if (e.key === 'Enter') {
            onSelectedSelf(candidates[selectedIndex]);
        }
    }
    const handleOnChange = e => {
        setInternalValue(e.target.value);
        setSelecting(false);
    };
    return <>
        <Component placeholder={placeholder} value={internalValue} onChange={handleOnChange}
                   onKeyDown={onKeyDown} required={required}
        />
        {
            showCandidates &&
            <ul className={styles.candidates}>
                {
                    candidates.map((candidate, i) =>
                        <li key={`acc-${key}-${i}`} className={i === selectedIndex ? styles.itemSelected : styles.item} onClick={e => onSelectedSelf(candidate)}>
                            <Candidate candidate={candidate} />
                        </li>
                    )
                }
            </ul>
        }
    </>;
};
