import React from "react";
import styles from "./ExhibitItem.module.scss";

const ExhibitItem = ({ exhibit }) => (
  <div className={styles.product}>
    <div className={styles.imageWrapper}>
      <img className={styles.image} src={ exhibit.images?.[0] } alt={ exhibit.name }/>
    </div>
    <div className={styles.name}>{ exhibit.name }</div>
  </div>
);

export default ExhibitItem;
