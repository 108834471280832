import React, { useState, useEffect, ReactNode } from 'react';
import { useDispatch } from "react-redux";
import axios from "axios";
import styles from "./Bank.module.scss";
import { setPaymentMethodFee } from './../../pages/checkout/CheckoutController';
import { useSelector, history } from "../../stores";

interface CodProps {
  children: ReactNode;
  price: number;
  onSubmit: (e: React.FormEvent) => Promise<void>;
};

const Cod = (props: CodProps) => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      const res = await axios.post('api/fee', { price: props.price });
      dispatch(setPaymentMethodFee(res.data.cod_fee));
    })();

    return (): void => { dispatch(setPaymentMethodFee(0)); };
  }, []);

  const handleOnSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setIsProcessing(true);
      await props.onSubmit(e);
    } catch {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleOnSubmit} className={styles.form}>
      {props.children}
      <button type="submit" className={styles.submitButton} disabled={isProcessing} >
          {isProcessing ? 'Processing...' : '購入'}
      </button>
    </form>
  );
};

export default Cod;
