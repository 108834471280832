import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { authContext } from './../../contexts/AuthProvider';
import { useSelector } from "./../../stores";
import { finishMounted } from "./../../stores/transition";
import { Form } from "react-bootstrap";
import { AddressComponent, newEmptyAddress } from "./../../components/address/AddressComponent";
import SnackBar from './../../components/snackbar/Snackbar';

interface IValues {
  user_name: string;
  tel: string;
};

const UserInfoPage = () => {
  const auth = useContext(authContext);
  const [values, setValues] = useState<IValues>({ user_name: auth.user.user_name || '', tel: auth.user.tel || '' });
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const dispatch = useDispatch();
  const isPaginating: boolean = useSelector(state => state.transition.isPaginating);
  useEffect(() => { if (isPaginating) dispatch(finishMounted()); }, [isPaginating]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleOnCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setValues({ ...values, [e.target.name]: e.target.checked });

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (isProcessing) return;
    try {
      setIsProcessing(true);
      await auth.update(values);
      setIsSuccess(true);
    } catch(err) {
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <Form.Group>
        <Form.Label> User Name </Form.Label>
        <Form.Control name="user_name" value={values.user_name} onChange={handleOnChange} />
      </Form.Group>

      <Form.Group>
        <Form.Label> Tel </Form.Label>
        <Form.Control name="tel" value={values.tel} onChange={handleOnChange} />
      </Form.Group>

      <Form.Group>
        <button type="submit" className="w-100" disabled={isProcessing} >
            {isProcessing ? 'Processing...' : '更新'}
        </button>
      </Form.Group>

      <SnackBar onClose={() => setIsSuccess(false)} show={isSuccess}>
        <>保存しました。</>
      </SnackBar>
    </Form>
  );
};

export default UserInfoPage;
