import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import InfiniteScroll from 'react-infinite-scroller';
import axios from './../../http';
import { finishMounted } from "./../../stores/transition";
import Loading from "./../../components/loading/Loading";
import OrderItem from "./OrderItem";

const OrdersListPage = () => {
  const dispatch = useDispatch();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [orders, setOrders] = useState<Array<object>>([]);

  if (isMounted) dispatch(finishMounted());

  useEffect((): void => {
    (async (): Promise<void> => {
      await load();
      setIsMounted(true);
    })();
  }, []);

  /**
   * 検索
   * @returns Promise<void>
   */
  const load = async (): Promise<void> => {
    if (isConnecting) return;
    try {
      setIsConnecting(true);
      const params = { page: page + 1 };
      const res = await axios.get('/api/orders', { params });
      setOrders([ ...orders, ...res.data.data]);
      setPage(res.data.meta.pagination.current_page);
      setTotalPage(res.data.meta.pagination.total_page);
    } catch (err) {
      console.error(err);
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <>
      <InfiniteScroll
          initialLoad={false}
          pageStart={page}
          loadMore={load}
          hasMore={page < totalPage}
          loader={<Loading key="loading" />}>
          {orders.map((order: any) => <OrderItem order={order} key={order.id} />)}
      </InfiniteScroll>
      {orders.length === 0 && <p className="text-center">購入履歴はありません</p>}
    </>
  );
};

export default OrdersListPage;
