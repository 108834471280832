import React from "react";
import { Toast } from "react-bootstrap";
import styles from "./Snackbar.module.scss";

interface IProps {
  children: React.ReactElement;
  onClose: () => void;
  show: boolean;
  delay?: number;
  variant?: string;
};

const SnackBar = (props: IProps) => (
  <div className={styles.wrapper}>
    <Toast onClose={props.onClose}
      show={props.show}
      delay={props.delay || 3000}
      className={`${styles[props.variant || 'primary']} ${styles.snackbar}`}
      autohide>
      <Toast.Body>{props.children}</Toast.Body>
    </Toast>
  </div>
);

export default SnackBar;
