import React, { useContext, useMemo } from 'react';
import { Redirect, Route } from 'react-router';
import { authContext } from './../contexts/AuthProvider';

interface IProps {
  component: React.FunctionComponent<any>;
  [x: string]: any;
}

const GuestRoute = ({ component, ...rest }) => {
    const auth = useContext(authContext);
    const handleRender = (props) =>
        auth.user ? (<Redirect to={{ pathname: '/', state: { from: props.location } }} />) : React.createElement(component, props);
    return (<Route {...rest} render={handleRender} />);
};

export default GuestRoute;
