import React, { ReactNode } from "react";
import { paginate } from '../stores/transition';
import { useDispatch } from "react-redux";

interface LinkProps {
    to: string;
    className?: string;
    onClick?: () => void,
    children: ReactNode;
}

export const Link = ({to, onClick, children, ...props}:LinkProps) => {
    const dispatch = useDispatch();

    const handleOnClick = e => {
        e.preventDefault();
        if (onClick) onClick();
        dispatch(paginate(to));
    };

    return (
        <a href={to} onClick={handleOnClick} {...props}>
            {children}
        </a>
    );
};
